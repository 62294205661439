//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CartIcon from "./CartIcon";
export default {
  components: {
    CartIcon
  }
};